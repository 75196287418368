import React, { ReactNode } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginContext } from "../../Contexts";
import LanguageString from "../LanguageString";
// import "./LaunchButton.css";
import "./LaunchButton_christmas.css";
import LoadingIcon2 from "../../Resources/loading2.svg";

type LaunchButtonProps = {
  patchStage?: number;
  showModal?: () => void;
  children?: ReactNode;
};

export default function LaunchButton({ children, patchStage, showModal }: LaunchButtonProps) {
  const [gameState, setGameState] = React.useState<typeof GameState>("stopped");
  const [dropdownShowing, setDropdownShowing] = React.useState(false);
  const loggedIn = React.useContext(LoginContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.ipcRenderer.on("gameLaunch", (event, running: boolean) => {
      setGameState(running ? "running" : "launching");
    });

    window.ipcRenderer.on("gameExit", () => {
      setGameState("stopped");
    });

    document.addEventListener("click", (event: MouseEvent) => {
      if (!menuRef.current?.contains(event.target as Node)) setDropdownShowing(false);
    });
  }, []);

  if (patchStage !== 0) return null;

  function clickLaunchButton() {
    if (gameState !== "stopped") return;

    if (!loggedIn) {
      if (showModal) showModal();
    } else {
      window.ipcRenderer.send("launchGame");
    }
  }

  function clickDropdownButton() {
    if (gameState !== "stopped") return;

    setDropdownShowing(!dropdownShowing);
  }

  let launchButtonText = "UI_TEXT_LAUNCH_BUTTON_PLAY";
  let launchButtonClass = "Launch-Button";
  let fileManagementButtonClass = "File-Management-Button";

  if (gameState !== "stopped") {
    launchButtonClass += " disabled";
    fileManagementButtonClass += " disabled";

    if (gameState === "running") launchButtonText = "UI_TEXT_LAUNCH_BUTTON_GAME_RUNNING";
  }

  let launchButtonContent = (
    <p className="Button-Text">
      <LanguageString stringId={launchButtonText} />
    </p>
  );
  if (gameState === "launching") {
    launchButtonContent = <img src={LoadingIcon2} alt="Loading..." className="Launcher-Loading-Icon" />;
  }

  return (
    <div className="Launch-Button-Container">
      <div className={launchButtonClass} onClick={clickLaunchButton}>
        {launchButtonContent}
      </div>
      <div className="File-Management-Button-Container">
        <div className={fileManagementButtonClass} onClick={clickDropdownButton} ref={menuRef}>
          <FontAwesomeIcon icon={faBars} className="Button-Text" />
        </div>
        {dropdownShowing && <ul className="Dropdown-Menu">{children}</ul>}
      </div>
    </div>
  );
}
