import React from "react";
import "./RegisterWindow.css";
import LanguageString from "./LanguageString";
import { ModalDismissForm, ModalWrapper } from "./ModalWindow";
import LoadingIcon from "./LoadingIcon";
import GameLogo from "../Resources/logo.png";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import AnimatedCheckmark from "./AnimatedCheckmark";

type RegisterWindowProps = {
  handleDismiss: () => void;
  onError: (err: string | ErrorObject) => void;
  openLoginWindow: () => void; // 추가된 prop
};

export default function RegisterWindow({ handleDismiss, onError, openLoginWindow }: RegisterWindowProps) {
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [passwordMismatch, setPasswordMismatch] = React.useState(false); // 비밀번호 불일치 상태
  const [invalidEmail, setInvalidEmail] = React.useState(false); // 이메일 유효성 상태
  const [invalidUsername, setInvalidUsername] = React.useState(false); // 아이디 유효성 상태
  const [invalidPassword, setInvalidPassword] = React.useState(false); // 비밀번호 유효성 상태
  const [registerCompleted, setRegisterCompleted] = React.useState<boolean>(false);

  const abortController = React.useRef(new AbortController());
  const usernameInput = React.useRef<HTMLInputElement>(null);
  const emailInput = React.useRef<HTMLInputElement>(null);
  const passwordInput = React.useRef<HTMLInputElement>(null);
  const passwordInput2 = React.useRef<HTMLInputElement>(null);
  const turnstileInput = React.useRef<TurnstileInstance>(null);
  const submitButton = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    usernameInput.current?.focus();
  }, []);

  // 유효성 검사 함수들
  function validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  // 아이디 유효성 검사: 최소 4자리, 최대 12자리, 영문자와 숫자만 허용
  function validateUsername(username: string): boolean {
    const usernamePattern = /^[a-zA-Z0-9]{4,20}$/; // 최소 4자리, 최대 12자리
    return usernamePattern.test(username);
  }

  // 비밀번호 유효성 검사: 최소 8자리, 최대 30자리, 최소 1개의 특수 문자 포함
  function validatePassword(password: string): boolean {
    const passwordPattern = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/; // 최소 8자리, 최대 30자리
    return passwordPattern.test(password);
  }

  // async function handleSubmit(event: React.FormEvent<HTMLFormElement>): Promise<boolean> {
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (loggingIn) return false;

    const elements = event.currentTarget.elements;
    const username = (elements.namedItem("username") as HTMLInputElement).value;
    const email = (elements.namedItem("email") as HTMLInputElement).value;
    const password = (elements.namedItem("password") as HTMLInputElement).value;
    const password2 = (elements.namedItem("password2") as HTMLInputElement).value;

    if (!username || !email || !password || !password2) return false;

    if (!validateUsername(username)) {
      setInvalidUsername(true);
      return false;
    }

    if (!validatePassword(password)) {
      setInvalidPassword(true);
      return false;
    }

    if (password !== password2) {
      setPasswordMismatch(true);
      return false;
    }

    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return false;
    }

    setLoggingIn(true);
    setInvalidUsername(false); // 아이디 유효성 검사 통과
    setInvalidPassword(false); // 비밀번호 유효성 검사 통과
    setPasswordMismatch(false); // 비밀번호가 일치하는 경우 초기화
    setInvalidEmail(false); // 이메일이 유효한 경우 초기화

    try {
      // const response = await fetch("https://launcher.dawntera.com/api/account/register", {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/account/register`, {
        method: "POST",
        body: JSON.stringify({ username, email, password, password2 }),
        credentials: "include",
        signal: abortController.current.signal,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      if (!data.success) {
        switch (data.error_code) {
          case "ACCEPT_TERMS_OF_SERVICE_REQUIRED": {
            onError(data.result_message);
            break;
          }
          case "INVALID_PASSWORD": {
            onError(data.result_message);
            break;
          }
          case "INVALID_USERNAME": {
            onError(data.result_message);
            break;
          }
          default: {
            onError(data.result_message);
          }
        }
        return false;
      }

      setRegisterCompleted(true);
      // setLoggedIn(data.username);
      // window.ipcRenderer.send("launchGame");

      // return true;
    } catch (err) {
      if (!(err instanceof DOMException) || err.name !== "AbortError") {
        onError("UI_TEXT_LOGIN_FAIL_UNABLE_TO_CONNECT");
        return false;
      }
      return true;
    } finally {
      setLoggingIn(false);

      if (passwordInput.current) passwordInput.current.value = "";
      if (passwordInput2.current) passwordInput2.current.value = "";
      if (submitButton.current) submitButton.current.disabled = true;
      if (turnstileInput.current) turnstileInput.current.reset();
    }
  }

  function onInputChange() {
    if (!usernameInput.current || !emailInput.current || !passwordInput.current || !passwordInput2.current || !submitButton.current) return;

    const isInvalidUsername = !validateUsername(usernameInput.current.value);
    const isInvalidPassword = !validatePassword(passwordInput.current.value);
    const isMismatch = passwordInput.current.value !== passwordInput2.current.value;
    const isInvalidEmail = !validateEmail(emailInput.current.value);

    setInvalidUsername(isInvalidUsername);
    setInvalidPassword(isInvalidPassword);
    setPasswordMismatch(isMismatch);
    setInvalidEmail(isInvalidEmail);

    submitButton.current.disabled = !usernameInput.current.value || !emailInput.current.value || !passwordInput.current.value || !passwordInput2.current.value || isMismatch || isInvalidEmail || isInvalidUsername || isInvalidPassword;
  }

  function abortRegister() {
    abortController.current.abort();
    setLoggingIn(false);
    handleDismiss();
  }

  if (registerCompleted) {
    return (
      <ModalWrapper handleDismiss={loggingIn ? abortRegister : handleDismiss} className="Register-Window">
        <section className="Register-Success-Wrapper" style={{ height: "400px" }}>
          <h2 className="Register-Success-Title">회원가입이 완료되었습니다.</h2>
          <AnimatedCheckmark />
          <p className="Register-Success">가입이 성공적으로 이루어졌습니다. 로그인 후 바로 테라에 접속하실 수 있습니다.</p>
          <p style={{ textAlign: "center", fontSize: "16px" }}>
            <div className="login-button">
              <br />
              <a onClick={openLoginWindow}>로그인 창으로 돌아가기</a>
            </div>
          </p>
        </section>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper handleDismiss={loggingIn ? abortRegister : handleDismiss} className="Register-Window">
      <div className="Register-Wrapper">
        <div className="Register-Title">
          <img src={GameLogo} alt="Logo" />
        </div>
        <div className="Register-Content">
          <form onSubmit={handleSubmit}>
            <input type="text" name="username" className={`Form-Input ${invalidUsername ? "error" : ""}`} placeholder="아이디" disabled={loggingIn} ref={usernameInput} onChange={onInputChange} />
            {invalidUsername && <div className="error-text">아이디는 4~20자리, 영어와 숫자만 가능합니다.</div>}

            <input type="text" name="email" className={`Form-Input ${invalidEmail ? "error" : ""}`} placeholder="이메일" disabled={loggingIn} ref={emailInput} onChange={onInputChange} />
            {invalidEmail && <div className="error-text">유효한 이메일 주소를 입력하세요.</div>}

            <input type="password" name="password" className={`Form-Input ${invalidPassword ? "error" : ""}`} placeholder="비밀번호" disabled={loggingIn} ref={passwordInput} onChange={onInputChange} />
            {invalidPassword && <div className="error-text">비밀번호는 8~30자리, 특수문자를 포함해야 합니다.</div>}

            <input type="password" name="password2" className={`Form-Input ${passwordMismatch ? "error" : ""}`} placeholder="비밀번호 확인" disabled={loggingIn} ref={passwordInput2} onChange={onInputChange} />
            {passwordMismatch && <div className="error-text">비밀번호가 일치하지 않습니다.</div>}

            <button type="submit" className="Form-Submit" ref={submitButton} disabled>
              <LanguageString stringId="UI_TEXT_REGISTER_MODAL_LOG_IN" />
            </button>

            <p style={{ textAlign: "center", fontSize: "14px", marginTop: "0", marginBottom: "20px" }}>
              <div className="login-button">
                계정이 이미 있습니까?
                <a onClick={openLoginWindow}> 로그인</a>
              </div>
            </p>
          </form>
        </div>
      </div>
      {loggingIn && <LoadingIcon dim />}
    </ModalWrapper>
  );
}
