// import "./App.css";
import "./App_christmas.css";
import React from "react";
import HeaderButton from "./Components/Buttons/HeaderButton";
import LaunchButton from "./Components/Buttons/LaunchButton";
import PatchProgress from "./Components/PatchProgress";
import LoginWindow from "./Components/LoginWindow";
import RegisterWindow from "./Components/RegisterWindow";
import PasswordResetWindow from "./Components/PasswordResetWindow";
import LogoutButton from "./Components/Buttons/LogoutButton";
import Carousel from "./Components/Carousel";
import { BannedWindow, ModalWindow, TerminationWindow, TermsOfServiceWindow } from "./Components/ModalWindow";
import { LanguageContext, LoginContext } from "./Contexts";
import SettingsWindow, { LanguageSelectSetting, LimitDownloadSpeedSetting, LauncherVersionSetting, AboutSetting } from "./Components/SettingsWindow";
import LanguageString from "./Components/LanguageString";
import ErrorInfo from "./Components/ErrorInfo";
import NewsContainer from "./Components/NewsContainer";
import GameLogo from "./Resources/logo.png";
import ArminBg from "./Resources/manahanseyefinal_new.mp4";
import SocialMediaButton from "./Components/Buttons/SocialMediaButton";
import ServiceStatus from "./Components/ServiceStatus";

export default function App() {
  const [language, setLanguage] = React.useState<LanguageType | null>(null);
  const [loggedIn, setLoggedIn] = React.useState<string | null>(null);
  const [patchStage, setPatchStage] = React.useState<number>(0);
  const [errorMsg, setErrorMsg] = React.useState<string | ErrorObject | null>(null);
  const [settingsModalVisible, setSettingsModalVisible] = React.useState(false);
  const [loginModalVisible, setLoginModalVisible] = React.useState(false);
  const [registerModalVisible, setRegisterModalVisible] = React.useState(false);
  const [passwordresetModalVisible, setPasswordResetModalVisible] = React.useState(false);
  const [tosModalVisible, setTosModalVisible] = React.useState(false);
  const [repairModalVisible, setRepairModalVisible] = React.useState(false);
  const [exitModal, setExitModal] = React.useState<GameExit | null>(null);
  const [bannedModal, setBannedModal] = React.useState<BanInfo | null>(null);
  const bgVideoRef = React.useRef<HTMLVideoElement>(null);
  const appOverlayRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const _username = window.localStorage.getItem("username");
    const _downloadSpeedLimit = window.localStorage.getItem("downloadSpeedLimit");
    const _language = window.localStorage.getItem("language");

    if (_language) setLanguage(_language as LanguageType);
    if (_username) {
      // Questionable cookie check
      if (document.cookie.includes("session_token=")) {
        setLoggedIn(_username);
      } else {
        window.localStorage.removeItem("username");
      }
    }
    if (_downloadSpeedLimit) window.ipcRenderer.send("setDownloadSpeedLimit", _downloadSpeedLimit);

    function onGameExit(_: unknown, info: GameExit) {
      if (info.code === 0) return;

      if (info.code === 403 && info.tosPending && !info.clientTerminated) {
        setTosModalVisible(true);
        return;
      }

      if (info.code === 4031) {
        setBannedModal({
          banAck: info.banAck ?? true,
          banExpirationDate: info.banExpirationDate ?? 0,
          banReason: info.banReason ?? -1,
        });
        return;
      }

      if (info.code === 401) {
        document.cookie = "session_token=; path=/; Max-Age=0";
        setLoggedIn(null);
      }

      setExitModal(info);
    }

    function onLoseFocus() {
      bgVideoRef.current?.pause();
    }

    function onGainFocus() {
      bgVideoRef.current?.play();
    }

    let bgTimeout: NodeJS.Timeout;

    function onVideoPlaying() {
      bgTimeout = setTimeout(() => {
        if (!appOverlayRef.current) return;

        appOverlayRef.current.style.opacity = "1";
      }, 5500);
    }

    window.ipcRenderer.on("gameExit", onGameExit);
    window.addEventListener("blur", onLoseFocus);
    window.addEventListener("focus", onGainFocus);
    bgVideoRef.current?.addEventListener("playing", onVideoPlaying, { once: true });

    return () => {
      window.removeEventListener("blur", onLoseFocus);
      window.removeEventListener("focus", onGainFocus);
      bgVideoRef.current?.removeEventListener("playing", onVideoPlaying);
      clearTimeout(bgTimeout);
    };
  }, []);

  React.useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => setErrorMsg(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  React.useEffect(() => {
    if (!language) return;

    window.localStorage.setItem("language", language);
  }, [language]);

  // 로그인 창에서 "새 계정 만들기" 클릭 시 회원가입 창을 열고, 로그인 창을 닫는 함수
  function openRegisterWindow() {
    setLoginModalVisible(false);
    setRegisterModalVisible(true);
  }

  // 로그인 창에서 "새 계정 만들기" 클릭 시 회원가입 창을 열고, 로그인 창을 닫는 함수
  function openPasswordResetWindow() {
    setLoginModalVisible(false);
    setPasswordResetModalVisible(true);
  }

  // 회원가입 창에서 로그인 창으로 돌아가는 함수
  function openLoginWindow() {
    setRegisterModalVisible(false);
    setLoginModalVisible(true);
  }

  const handleClick = (url: string) => {
    // 외부 링크를 열기 위해 ipcRenderer를 사용
    window.ipcRenderer.send("openExternal", url);
  };

  return (
    <div className="App">
      <LanguageContext.Provider value={language ?? "ko"}>
        <LoginContext.Provider value={loggedIn}>
          <header className="App-header">
            <span className="Game-Title">DAWN Launcher</span>
            <span className="Game-Version">3.3</span>
            {/* <ServiceStatus /> */}
            <ul className="Header-Buttons">
              {loggedIn && (
                <HeaderButton type="account">
                  <LogoutButton action={() => setLoggedIn(null)} onError={(err) => setErrorMsg(err)} />
                </HeaderButton>
              )}
              {/* {window.proxyAvailable && <HeaderButton type="proxy" />} */}
              <HeaderButton type="settings" showModal={() => setSettingsModalVisible(true)} />
              <HeaderButton type="minimize" />
              <HeaderButton type="close" />
            </ul>
          </header>
          <main className="App-body">
            {/* <video autoPlay muted loop className="App-Body-Background" ref={bgVideoRef}>
              <source src={ArminBg} type="video/mp4" />
            </video> */}
            {/* <div className="App-Body-Overlay" ref={appOverlayRef}></div> */}
            <div className="App-Body-Overlay" />
            <div className="App-Body-Content">
              <div className="Main-Area">
                <div className="Game-Logo">
                  <img src={GameLogo} alt="DAWN Launcher" />
                </div>
                {/* 공지 이미지 */}
                {/* <Carousel /> */}
                {/* <NewsContainer /> */}
                <div className="Footer">
                  <PatchProgress patchStage={patchStage} setPatchStage={setPatchStage} />
                  <LaunchButton patchStage={patchStage} showModal={() => setLoginModalVisible(true)}>
                    <li onClick={() => window.ipcRenderer.send("clientUpdate")}>
                      <LanguageString stringId="UI_TEXT_CLIENT_MANAGE_DROPDOWN_CHECK_FOR_UPDATES" />
                    </li>
                    <li onClick={() => setRepairModalVisible(true)}>
                      <LanguageString stringId="UI_TEXT_CLIENT_MANAGE_DROPDOWN_REPAIR_GAME" />
                    </li>
                    <li onClick={() => window.ipcRenderer.send("openGameFolder")}>
                      <LanguageString stringId="UI_TEXT_CLIENT_MANAGE_DROPDOWN_OPEN_GAME_FOLDER" />
                    </li>
                  </LaunchButton>
                </div>
                {window.gameEnvironment === "test" && <p className="Test-Environment">TEST ENVIRONMENT</p>}

                <div className="Artwork-Credit">
                  <p>
                    Artwork by{" "}
                    <a href="#" onClick={() => handleClick("https://nightflower1012.wixsite.com/potmum/illust")}>
                      Kikusan
                    </a>{" "}
                    (
                    <a href="#" onClick={() => handleClick("https://x.com/Skyblue_mum")}>
                      @skyblue_mum
                    </a>
                    )
                  </p>
                </div>
              </div>
              <ul className="Social-Media-Area">
                {/* <SocialMediaButton type="homepage" url="https://dawntera.com" /> */}
                {/* <SocialMediaButton type="homepage" url="https://mtdream.net" /> */}
                <SocialMediaButton type="discord" url="https://discord.gg/dawntera" />
                {/* <SocialMediaButton type="moongourd" url="https://moongourd.mtdream.net" /> */}
                {/* <SocialMediaButton type="twitch" url="https://twitch.tv/themtdream" /> */}
                {/* <SocialMediaButton type="vk" url="https://vk.com/mtdream" /> */}
              </ul>
            </div>
          </main>
          {settingsModalVisible && (
            <SettingsWindow handleDismiss={() => setSettingsModalVisible(false)}>
              <LanguageSelectSetting setLanguage={setLanguage} />
              <LimitDownloadSpeedSetting />
              <LauncherVersionSetting />
              <AboutSetting />
            </SettingsWindow>
          )}
          {loginModalVisible && (
            <LoginWindow
              setLoggedIn={setLoggedIn}
              onError={setErrorMsg}
              handleDismiss={() => setLoginModalVisible(false)}
              openRegisterWindow={openRegisterWindow} // 회원가입 창을 열기 위한 prop 전달
              openPasswordResetWindow={openPasswordResetWindow} // 회원가입 창을 열기 위한 prop 전달
            />
          )}
          {registerModalVisible && <RegisterWindow onError={setErrorMsg} handleDismiss={() => setRegisterModalVisible(false)} openLoginWindow={openLoginWindow} />}
          {passwordresetModalVisible && <PasswordResetWindow onError={setErrorMsg} handleDismiss={() => setPasswordResetModalVisible(false)} openLoginWindow={openLoginWindow} />}
          {tosModalVisible && <TermsOfServiceWindow onError={setErrorMsg} handleDismiss={() => setTosModalVisible(false)} />}
          {repairModalVisible && <ModalWindow title="UI_TEXT_CLIENT_REPAIR_MODAL_TITLE" body="UI_TEXT_CLIENT_REPAIR_MODAL_MESSAGE" buttonText="UI_TEXT_CLIENT_REPAIR_MODAL_BUTTON_START" onConfirm={() => window.ipcRenderer.send("clientRepair")} handleDismiss={() => setRepairModalVisible(false)} />}
          {bannedModal && <BannedWindow info={bannedModal} handleDismiss={() => setBannedModal(null)} onError={setErrorMsg} />}
          {exitModal && <TerminationWindow info={exitModal} handleDismiss={() => setExitModal(null)} />}
          {errorMsg && <ErrorInfo errorMsg={errorMsg} onClick={() => setErrorMsg(null)} />}
        </LoginContext.Provider>
      </LanguageContext.Provider>
    </div>
  );
}
